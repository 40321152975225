export default {
  "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2CB is another fine product of The Nonsense Factory 2023 - "])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "warn_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WARNING!"])},
  "warn_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The record will be deleted?"])},
  "warn_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "imageUploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image uploaded"])},
  "imageUploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during image upload"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
  "isInHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is in the homepage"])},
  "isNotInHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is not in the homepage"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egeanet"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frazione Cervignasco 11 - 12037 Saluzzo (CN) Italy"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+", "39 0175 217323"])},
  "companyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["info", "@", "egeamusic.com"])},
  "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright Egeamusic. All Rights Reserved"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["go back"])},
  "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
  "error404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404"])},
  "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
  "apiErrors": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Found"])},
    "generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error contacting the server. Try to login again."])}
  },
  "importer": {
    "importExcel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel import"])},
    "importLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the Excel File"])},
    "importBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import"])},
    "downloadTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Excel template"])}
  },
  "auth": {
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These credentials do not match our records."])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided password is incorrect."])},
    "throttle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many login attempts. Please try again in :seconds seconds."])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "notUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a user?"])},
    "signupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is you company interested in buying from Egeanet? Call us at 0175 217323 or e-mail us at"])},
    "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat the password"])}
  },
  "filters": {
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for the brand"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for the category"])},
    "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for the subcategory"])},
    "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for the author"])}
  },
  "backoffice": {
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
      "brands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands"])},
      "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
      "subcategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategories"])},
      "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
      "customerGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer groups"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
      "discountRules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount rules"])},
      "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
      "shippingMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping methods"])},
      "uoms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement units"])},
      "warehouses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouses"])},
      "warehouseTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse transactions"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])}
    },
    "categories": {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Category"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Category"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Category"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "inHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be in homepage"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Categories from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories have been imported"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])}
      }
    },
    "brands": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Brand"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Brand"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Brand"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
      "inHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be in homepage"])},
      "urlLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo url"])},
      "noLogoExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No logo exist"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Brands from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brands have been imported"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])}
      },
      "selectFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the files"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
      "dragFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drag the images here"])},
      "brandLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand logo"])}
    },
    "uoms": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement Units Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Measurement Unit"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Measurement Unit"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "conversionRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion Ratio"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Unity of Measure from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit of Measure have been imported"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])}
      }
    },
    "vats": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])}
    },
    "attributeTags": {
      "attributeTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribute tags"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Attribute Tag"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Attribute Tag"])},
      "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "alreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["already exist"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])}
      },
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "iconUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Icon URL"])},
      "types": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["text"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["url"])},
        "iframe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iframe"])},
        "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["list"])},
        "fixedList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fixed_list"])}
      },
      "newOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add option to the list"])},
      "addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "fullIndexed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Text Search"])}
    },
    "subCategories": {
      "subCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subcategory"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategories Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Subcategory"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Subcategory"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Subcategory"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "inHomepage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be in homepage"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Subcategories from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategories have been imported"])},
      "referringCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referring Category"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])}
      },
      "attributeTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribute tags"])}
    },
    "products": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Product"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totals"])},
      "priceWithTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price+VAT"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Product"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Product"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "supplierSku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Code"])},
      "erpProductCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP Code"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategory"])},
      "stockInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
      "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "extendedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended Description"])},
      "ean13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN13"])},
      "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
      "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
      "lowStockThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Stock Threshold"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "shippingPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Price"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
      "availableForOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for Orders"])},
      "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Stock"])},
      "availableFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available from"])},
      "availableForPreorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for preorders"])},
      "availableOnRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on request"])},
      "hasVariants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variants Availables"])},
      "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered"])},
      "uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement unit"])},
      "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yyyy-MM-dd"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Products from Excel"])},
      "importImagesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Product Images from Excel"])},
      "importAttributesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Product Attributes from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products import has been queued"])},
      "withTaxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Taxes"])},
      "onRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Request"])},
      "discountedPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounted Price"])},
      "taxValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Value"])},
      "messages": {
        "GTZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be greather than 0"])},
        "isbnLen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN must be 10 or 13 digits"])},
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])}
      },
      "addVariants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add variants"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributes"])},
      "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
      "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
      "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
      "stocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stocks"])},
      "onHand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On hand"])},
      "onOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On order"])},
      "checkWarehouseMovements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the warehouse movements"])},
      "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover image"])},
      "productImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product images"])}
    },
    "variants": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variants Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Variant"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Variant"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "supplierSku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Code"])},
      "erpProductCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP Code"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marchio"])},
      "variant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variant"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategory"])},
      "stockInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Info's"])},
      "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "extendedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended Description"])},
      "ean13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAN13"])},
      "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
      "upc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPC"])},
      "lowStockThreshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low Stock Threshold"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
      "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight"])},
      "depth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depth"])},
      "availableForOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for Orders"])},
      "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Stock"])},
      "availableFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available from"])},
      "availableForPreorders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available for preorders"])},
      "availableOnRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on request"])},
      "hasVariants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variants Availables"])},
      "uom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurement unit"])},
      "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yyyy-MM-dd"])},
      "messages": {
        "GTZero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be between 1 and 4"])},
        "isbnLen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN must be 10 or 13 digits"])},
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])}
      },
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
      "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered"])},
      "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["default"])},
      "attributes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributes"])},
      "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
      "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])}
    },
    "orders": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Order"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Order"])},
      "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
      "closeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Order"])},
      "closedOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order successfully closed!"])},
      "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Channel"])},
      "billingCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Company"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
      "currentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Status"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Orders from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders have been imported"])},
      "availableFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available from"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunghezza invalida"])}
      },
      "orderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Header"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
      "shippingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Costs"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order status"])},
      "orderNumberERP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number ERP"])},
      "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method:"])},
      "shippingMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping Method:"])},
      "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shipping Address"])},
      "orderRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order rows"])},
      "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["billing Address"])},
      "noProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products selected"])},
      "noCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No customer found"])}
    },
    "shippingMethods": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Methods Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Shipping Method"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Shipping Method"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping Threshold"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Shipping Methods from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Methods have been imported"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])}
      }
    },
    "customerGroups": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Groups Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Customer Group"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Customer Group"])},
      "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "pantheraGroupId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Group Id"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Customer Groups from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Groups have been imported"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])}
      }
    },
    "customers": {
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
      "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Customer"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Customer"])},
      "showTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Customer"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "isERPConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is ERP connected?"])},
      "customerGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Group"])},
      "ordersState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders State"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
      "sdiCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sdi Code"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "pec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pec"])},
      "availableFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available From"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Job"])},
      "vatId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vat Id"])},
      "taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax number"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
      "taxRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
      "preorderDaysAdvanceOnShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre-order Days"])},
      "taxDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax %"])},
      "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter"])},
      "newsletterOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter Option"])},
      "newsletterDateAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter Date Add"])},
      "panthera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera Code"])},
      "erp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erp Code"])},
      "getPantheraCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Panthera Code"])},
      "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yyyy-MM-dd"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Customer from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers have been imported"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the country"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "searchCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by Customer fullname"])},
      "customerDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Discount"])},
      "extraCee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra CEE"])},
      "addresses": {
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
        "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
        "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
        "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["co"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
        "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Address"])},
        "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Address"])},
        "billing": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])}
        },
        "shipping": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Addresses"])}
        }
      },
      "panthera_customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera customers"])},
      "get_panthera_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["get panthera code"])},
      "new_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new Panthera customer"])},
      "toast_create_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added Panthera customer successfully"])},
      "toast_panthera_customer_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer not found"])},
      "toast_customer_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panthera customer selected correctly"])},
      "add_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new Panthera Customer"])},
      "panthera_updatableCustomers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add or Create Customer on Panthera"])},
      "customer_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to find a Customer on Panthera"])},
      "select_panthera_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Panthera Customer"])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "customer_not_in_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The customer you are looking for isn't in the list?"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])},
        "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
        "invalidVatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The VAT Number seems incorrect or the VAT is not active. Could you check it?"])},
        "invalidTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Tax Number provided seems not to be correct. Could you check it?"])},
        "validTaxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Tax Number seems valid"])},
        "validVatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The VAT Number seems valid"])},
        "userCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User successfully created"])},
        "passwordRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password length must be at least 8 character and must contain at least a number, an uppercase character and a special character."])},
        "confirmPassworddDoesntMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password doesn't match."])}
      },
      "customerAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addresses"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user"])}
    },
    "discountRules": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Rules Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Discount Rule"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Discount Rule"])},
      "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
      "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
      "blockOthers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Others Discounts"])},
      "customerGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Group"])},
      "productCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Category"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Discount Rules from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount Rules have been imported"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])},
        "ruleWithBlockOthersAlreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Discount Rule already exist with the block other flag set. Unset the block other flag in this rule."])},
        "sameRuleAlreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Discount Rule already exist with the same priority for this Customer Gruoup / Product Category pair."])}
      },
      "maxLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max limit reached"])}
    },
    "campaigns": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns Management"])},
      "newCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New campaign"])},
      "addProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new products to the campaign"])},
      "searchProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a product"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start date"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end date"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "inHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In homepage"])},
      "productList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product list"])},
      "describeCampaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give a brief description of the campaign"])},
      "campaignTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give this campaign a title"])}
    },
    "warehouses": {
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouses Management"])},
      "addTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Warehouse"])},
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Warehouse"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "managementType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Type"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import Warehouse(s) from Excel"])},
      "imported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse(s) have been imported"])},
      "messages": {
        "invalidLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid length"])},
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])},
        "notspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Specified"])}
      }
    },
    "transactions": {
      "documentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Date"])},
      "documentNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document #"])},
      "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
      "productVariantCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product variant code"])},
      "previousStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Stock"])},
      "resultStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result Stock"])},
      "productSku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SKU"])},
      "importTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import transactions from Excel"])},
      "pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouses transactions"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse code"])},
      "inventoryInitTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory Initialization Import"])},
      "inventoryImported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory Initialization Imported"])},
      "productTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse Transactions"])}
    },
    "attributes": {
      "editTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Attribute"])}
    }
  },
  "frontoffice": {
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
      "music": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
      "essential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essential"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
      "comingUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming up"])},
      "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo"])},
      "inStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In stock"])},
      "subCategories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategories"])},
      "homeVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home video"])},
      "hi-fi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi-fi"])},
      "recordPlayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record player"])},
      "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi-fi accessories"])},
      "merchindise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchindise"])},
      "clothing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothing"])},
      "gadget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gadgets"])},
      "publishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing"])},
      "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
      "sheetMusic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sheet music"])}
    },
    "cart": {
      "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incl. VAT"])},
      "novat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excl. VAT"])},
      "vatAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT amount"])},
      "vatPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vat %"])},
      "shippingCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping costs calculate in the next page"])},
      "shippingNational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price for national shipping"])},
      "shippingInternational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price for international shipping"])},
      "shippingVatAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping VAT amount"])},
      "shippingMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Method"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Price"])},
      "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
      "shippingAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Addresses"])},
      "messages": {
        "invalidValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])},
        "productAddedToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product added to cart"])}
      },
      "productsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your products"])},
      "emptyCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cart is empty"])},
      "goToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to checkout"])}
    },
    "userData": {
      "myData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MY DATA"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your profile"])},
      "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job"])},
      "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT id"])},
      "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
      "changeLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change default language"])},
      "accessData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCESS DATA"])},
      "mySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SETTINGS"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "cannotChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot change user type because you have orders in your name."])},
      "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new profile to place orders for another user type."])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "dateFormatPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/mm/yyyy"])},
      "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/MM/yyyy"])}
    },
    "products": {
      "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image for"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product details"])},
      "ordered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
      "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upcoming"])},
      "moreDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+ details"])},
      "searchProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a product"])},
      "latest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest"])},
      "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brand"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
      "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subcategory"])},
      "outOfStock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out of Stock"])},
      "isDiscontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discontinued"])},
      "noVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. VAT"])},
      "shippingExcl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. shipping price"])}
    },
    "notifications": {
      "errorReload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was an error. Please reload the page"])},
      "outOfStockFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product"])},
      "outOfStockSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been removed from the cart because it is currently out of stock."])},
      "noMoreLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are no longer logged in!"])}
    },
    "checkout": {
      "orderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete Order"])},
      "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing..."])},
      "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
      "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method:"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "workingOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for order creation ..."])},
      "addShippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new shipping address"])},
      "editShippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit a new shipping address"])},
      "messages": {
        "insertOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error as occurred inserting the order. Retry later."])},
        "orderInserted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order inserted succesfully."])}
      },
      "sameAsBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same as billing address"])},
      "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing Address"])},
      "shippingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Address"])},
      "shippingAddresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Addresses"])},
      "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C/O"])}
    },
    "coupons": {
      "hasACoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a coupon?"])},
      "writeCoupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write the coupon code:"])},
      "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])}
    },
    "profile": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
      "greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi"])},
      "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
      "orderRows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opened order rows"])},
      "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Data"])},
      "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Addresses"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Documents"])},
      "coupons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupons"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
      "editAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit shipping address"])},
      "deleteAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete shipping address"])},
      "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this address?"])},
      "order": {
        "productSku": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product sku"])},
        "productDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product description"])},
        "totQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot ordered quantity"])}
      }
    },
    "documents": {
      "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
      "credit-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Notes"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Date"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Amount"])}
    },
    "orders": {
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order number"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order date"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order amount"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order status"])},
      "status-in_work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working On It"])},
      "status-shipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipped"])},
      "status-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
      "status-registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered"])},
      "status-pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "status-failed_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment failed"])}
    },
    "order": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order details"])},
      "invoice-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Invoice"])},
      "status-processed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Processed"])},
      "status-transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. In Transit"])},
      "status-delivered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Delivered"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])},
      "billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billed to"])},
      "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping address"])},
      "shipping-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping method"])},
      "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
      "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "inc-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incl. VAT"])},
      "tracking-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracking information"])},
      "tracking-sentence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the status of your shipping "])},
      "tracking-here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order pending payment."])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If payment won't be made within one week, this order will be canceled."])},
      "noVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. VAT"])},
      "waitingConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for payment confirmation"])},
      "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free Shipping"])}
    },
    "addressForm": {
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
      "co": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C/O"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street/Square"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
      "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
      "taxNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
      "taxNumberBracket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(mandatory only for Italian citizens)"])},
      "taxNumberMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tax code does not correspond to the code of a physical person. If you are a private company, please contact support by email."])}
    },
    "payments": {
      "stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Card"])},
      "paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PayPal"])},
      "paypal-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be redirected to Paypal website"])},
      "stripe-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Payment..."])},
      "sepa-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing Payment..."])},
      "sofort-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be redirected to Sofort website"])},
      "sepa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sepa Direct Debit"])},
      "sofort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofort Banking"])},
      "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash on Delivery"])}
    },
    "password": {
      "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
      "old": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
      "newConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
      "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save new password"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been updated"])},
      "updateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred while updating your password. Please try again later."])},
      "messages": {
        "passwordRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password length must be at least 8 character and must contain at least a number, an uppercase character and a special character."])},
        "confirmPassworddDoesntMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password doesn't match."])}
      }
    },
    "settings": {
      "showDiscontinued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show discontinued"])},
      "saveData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Settings"])},
      "toastSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings Saved"])},
      "toastError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error saving settings"])}
    },
    "searchBox": {
      "HOME_VIDEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Video"])},
      "MUSICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
      "ABBIGLIAMENTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothing"])},
      "ACCESSORI_HIFI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi-Fi Accessories"])},
      "EDITORIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing"])},
      "GADGET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gadgets"])},
      "GIRADISCHI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turntables"])},
      "LIBRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books"])},
      "MERCHANDISE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merchandise"])},
      "MOBILETTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabinet"])},
      "SPARTITI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sheet Music"])},
      "A.P.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A.P."])},
      "BLURAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blu-ray"])},
      "BLURAY_HOME_VIDEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blu-ray Home Video"])},
      "CD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CD"])},
      "CD-S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CD-S"])},
      "DUALDISC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DualDisc"])},
      "DVD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVD"])},
      "DVD_HOME_VIDEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DVD Home Video"])},
      "EP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EP"])},
      "LIBRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book"])},
      "LP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP"])},
      "LP-Mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP-Mix"])},
      "MC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MC"])},
      "SACD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SACD"])},
      "SPAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPAR"])},
      "T-SHIRT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T-SHIRT"])},
      "VHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VHS"])}
    }
  },
  "homepage": {
    "exclusiveDistribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive distribution"])}
  }
}