import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: i18n.global.t('frontoffice.navbar.home'),
    },
  },
  {
    path: '/sign-in',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: i18n.global.t('login.login'),
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/cart',
    name: 'cart',
    meta: { requiresAuth: true },
    component: () => import('@/views/CartView.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    requiresAuth: true,
    component: () => import('../views/CheckoutView.vue'),
  },
  {
    path: '/backoffice',
    name: 'backoffice',
    children: [
      {
        path: 'categories',
        name: 'backoffice-categories',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-categories" */ '../views/backoffice/categories/CategoriesView.vue'),
      },
      {
        path: 'categories/:id',
        props: true,
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/backoffice/categories/CategoryDetails.vue'),
        children: [
          {
            path: '',
            name: 'categories-info',
            props: true,
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/categories/CategoryFormItem.vue'),
          },
          {
            path: 'attribute-tags',
            name: 'categories-attribute-tags',
            props: true,
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/attribute-tags/AttributeTagsTable.vue'),
          },
        ],
      },
      {
        path: 'brands',
        name: 'backoffice-brands',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-brands" */ '../views/backoffice/brands/BrandsView.vue'),
      },
      {
        path: 'uoms',
        name: 'backoffice-uoms',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-uoms" */ '../views/backoffice/measurement-units/MeasurementUnitsView.vue'),
      },
      {
        path: 'subcategories',
        name: 'backoffice-sub-categories',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-sub-categories" */ '../views/backoffice/sub-categories/SubCategoriesView.vue'),
      },
      {
        path: 'subcategories/:id',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/backoffice/sub-categories/SubCategoryDetails.vue'),
        children: [
          {
            path: '',
            name: 'subcategories-info',
            props: true,
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/sub-categories/SubCategoryFormItem.vue'),
          },
          {
            path: 'attribute-tags',
            name: 'subcategories-attribute-tags',
            props: true,
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/attribute-tags/AttributeTagsTable.vue'),
          },
        ],
      },
      {
        path: 'products',
        name: 'backoffice-products',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-products" */ '../views/backoffice/products/ProductsView.vue'),
      },
      {
        path: 'products/:sku',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/backoffice/products/ProductDetails.vue'),
        children: [
          {
            path: '',
            name: 'product-info',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/products/ProductInfoDetails.vue'),
          },
          {
            path: 'attributes',
            name: 'attributes',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/products/ProductAttributeDetails.vue'),
          },
          {
            path: 'purchase',
            name: 'purchase',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/products/ProductPurchaseDetails.vue'),
          },
          {
            path: 'sales',
            name: 'sales',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/products/ProductSalesDetails.vue'),
          },
          {
            path: 'images',
            name: 'images',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/products/ProductImagesDetails.vue'),
          },
          {
            path: 'variants',
            name: 'product-variants',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/variants/VariantTable.vue'),
          },
          {
            path: 'add-variant',
            name: 'add-variant-form',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/variants/VariantFormItem.vue'),
          },
          {
            path: 'product-warehouse',
            name: 'warehouse',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/products/ProductWarehouseDetails.vue'),
          },
        ],
      },
      {
        path: 'orders',
        name: 'backoffice-orders',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-orders" */ '../views/backoffice/orders/OrdersView.vue'),
      },
      {
        path: 'shipping-methods',
        name: 'backoffice-shipping-methods',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-shipping-methods" */ '../views/backoffice/shipping-methods/ShippingMethodsView.vue'),
      },
      {
        path: 'customer-groups',
        name: 'backoffice-customer-groups',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-customer-groups" */ '../views/backoffice/customer-groups/CustomerGroupsView.vue'),
      },
      {
        path: 'customers',
        name: 'backoffice-customers',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-customers" */ '../views/backoffice/customers/CustomersView.vue'),
      },
      {
        path: 'customers/:id',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/components/backoffice/customers/CustomerDetails.vue'),
        children: [
          {
            path: '',
            name: 'customer',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/customers/EditCustomer.vue'),
          },
          {
            path: 'customer-addresses',
            name: 'customer-addresses',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/customers/CustomerAddress.vue'),
          },
          {
            path: 'customer-user',
            name: 'customer-user',
            meta: {
              requiresAuth: true,
            },
            component: () => import('@/components/backoffice/customers/UserRegistrationForm.vue'),
          },
        ],
      },
      {
        path: 'discount-rules',
        name: 'backoffice-discount-rules',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-discount-rules" */ '../views/backoffice/discount-rules/DiscountRulesView.vue'),
      },
      {
        path: 'campaigns',
        name: 'campaigns',
        meta: {
          requiresAuth: true,
        },
        component: () => import('../views/backoffice/campaigns/CampaignsView.vue'),
      },
      {
        path: 'warehouses',
        name: 'backoffice-warehouses',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-warehouses" */ '../views/backoffice/warehouses/WarehousesView.vue'),
      },
      {
        path: 'warehouse-transactions',
        name: 'backoffice-warehouse-transactions',
        meta: {
          requiresAuth: true,
        },
        // eslint-disable-next-line
        component: () => import(/* webpackChunkName: "backoffice-warehouse-transactions" */ '../views/backoffice/warehouse-transactions/WarehouseTransactionsView.vue'),
      },
    ],
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/ProductsView.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('backoffice.navbar.products'),
    },
  },
  {
    path: '/products/:sku',
    name: 'product',
    component: () => import('@/views/ProductView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "checkout" */ '@/views/UserProfile.vue'),
    meta: { requiresAuth: true, customer: true },
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import('@/components/profile/UserData.vue'),
        meta: { requiresAuth: true, customer: true },
      },
      {
        path: 'orders',
        component: () => import('@/components/profile/ProfileOrders.vue'),
        meta: { requiresAuth: true, customer: true },
      },
      {
        path: 'order-rows',
        component: () => import('@/components/profile/ProfileOpenOrderRows.vue'),
        meta: { requiresAuth: true, customer: true },
      },
      {
        path: 'orders/:order_no',
        name: 'single-order',
        component: () => import('@/components/profile/ProfileOrder.vue'),
        meta: { requiresAuth: true, customer: true },
      },
      {
        path: 'addresses',
        component: () => import('@/components/profile/ProfileAddresses.vue'),
        meta: { requiresAuth: true, customer: true },
      },
      {
        path: 'documents',
        component: () => import('@/components/profile/ProfileDocuments.vue'),
        meta: { requiresAuth: true, customer: true },
        children: [
          {
            path: '',
            name: 'invoices',
            component: () => import('@/components/profile/ProfileInvoices.vue'),
            meta: { requiresAuth: true, customer: true },
          },
          {
            path: 'credit-notes',
            name: 'credit-notes',
            component: () => import('@/components/profile/ProfileCreditNotes.vue'),
            meta: { requiresAuth: true, customer: true },
          },
          {
            path: 'DDT',
            name: 'DDT',
            component: () => import('@/components/profile/ProfileDDT.vue'),
            meta: { requiresAuth: true, customer: true },
          },
        ],
      },
      {
        path: 'coupons',
        component: () => import('@/components/profile/ProfileCoupons.vue'),
        meta: { requiresAuth: true, customer: true },
      },
    ],
  },
  {
    path: '/new-campaign',
    name: 'new-campaign',
    props: true,
    component: () => import('../components/backoffice/campaigns/AddCampaign.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/views/Error404View.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => { //eslint-disable-line
  document.title = to.meta.title ? `Egeanet ${to.meta.title}` : 'Egeanet';
  await store.dispatch('auth/me');
  if (to.meta.requiresAuth && !store.getters['auth/authenticated']) {
    return {
      path: '/',
      query: { redirect: to.fullPath },
    };
  }
  return true;
});

export default router;
